#react-paginate ul {
    display: inline-block;
    margin-left: 20px;
    padding-left: 0;
}

#react-paginate li {
    display: inline-block;
    border: 1px solid rgb(224, 224, 224);
    color: #000;
    cursor: pointer;
    margin-right: 3px;
    border-radius: 5px;
    margin-bottom: 5px;
}

#react-paginate li a {
    padding: 4px 9px;
    width: 26px;
    height: 26px;
    display: inline-block;
    color: #000;
    outline: none;
}

#react-paginate li a:hover {
    background: rgb(224, 224, 224);
}

#react-paginate li.active {
    background: rgb(224, 224, 224);
    outline: none;
}