.fx {
  display: flex;
  box-sizing: border-box;
}
.rounded-div {
  border: 1px solid silver;
  border-radius: 10px;
  max-width: 75ch;
  margin: 10px 0 20px 0;
  padding: 0.35em 0.625em 0.75em;
  flex: 1 0 50%;
}
.fx .rounded-div {
  width: 100%;
  margin-right: 15px;
}
.fx .green {
  border-color: #20bc68;
}

.fx .red {
  border-color: #ea5d5d;
}

.pro-icon,
.con-icon {
  position: absolute;
  font-size: 1.3em;
}
.pro-icon {
  margin: -2.1em 0px 0px 5ch;
  color: forestgreen;
}
.pro_bullet,
.con_bullet {
  padding-bottom: 0 !important;
}
.rounded-div legend {
  padding: 0 15px 10px;
  font-weight: 700;
  width: initial;
  font-size: 22px;
}

.rounded-div legend > img {
  width: 22px;
  padding-left: 3px;
}

.green > legend > img {
  top: -4px;
}

.red > legend > img {
  top: 4px;
}

.rounded-div li {
  list-style: none;
  padding: 0 30px 10px;
  background-repeat: no-repeat;
  background-position: left -2px;
  background-size: 27px;
}

.pro_bullet li {
  background-image: url(https://plugins-media.makeupar.com/smb/story/2024-03-29/521534d2-a47d-46d0-b7b8-c5ba0fd35578.png);
}

.con_bullet li {
  background-image: url(https://plugins-media.makeupar.com/smb/story/2024-03-29/01d9584a-49de-4e77-ab32-d8e12b99d9df.png);
}
