.header-help__dropdown {
    position: absolute;
    left: -65px;
}

@media (max-width: 1439px) {
    .header-help__dropdown {
        position: absolute;
        left: -100px;
    }
}

@media (max-width: 1024px) {
    .header-help__dropdown {
        position: absolute;
        left: -80px;
    }
}