.learn-button {
  background-color: #f23b77;
  border: 1px solid #f23b77;
  color: #fff;
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 4px;
  max-width: 100px;
}

.learn-button:hover {
  background-color: #ff72a0;
  border: 1px solid #ff72a0;
}

.jodit_placeholder {
  z-index: 0 !important;
}

.learn-button--speakerbtn {
  display: inline-block;
  margin: 0 10px;
}
