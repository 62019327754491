.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.modal-open {
  padding-right: 0 !important;
}

@media (min-width: 500px) {
  body {
    overflow-y: scroll;
  }
}

.btn.nowrap {
  white-space: nowrap;
}

.bg-grey-steel .arrow{
  transition: color 150ms;
  color: hsl(0, 0%, 80%);
}

.bg-grey-steel .arrow:hover {
  color: hsl(0, 0%, 60%);
}

.mt-element-ribbon {
  position: relative;
  margin-bottom: 30px;
}

.mt-element-ribbon .ribbon.ribbon-vertical-left {
  clear: none;
  margin: -2px 0 0 10px;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 41px;
  text-align: center;
}
.mt-element-ribbon .ribbon,
.mt-element-ribbon .ribbon.ribbon-color-default,
.mt-element-ribbon .ribbon.ribbon-color-default > .ribbon-sub,
.mt-element-ribbon .ribbon > .ribbon-sub {
  background-color: #337ab7;
  color: #fff;
}
.mt-element-ribbon .ribbon {
  padding: 0.5em 1em;
  float: left;
  margin: 10px 0 0 -2px;
  clear: left;
  position: absolute;
}
.uppercase {
  text-transform: uppercase !important;
}
.portlet > .portlet-body div {
  margin-top: 0;
}
.mt-element-ribbon .ribbon-content {
  margin: 0;
  padding: 25px;
  clear: both;
}


.module-preview-img{
  border: 1px #000000 solid;
  width: 75%;
  cursor: pointer;
}

.module-preview-img.selected{
  border:3px #fd397a solid;
}

.layout-preview label{
  font-weight: 500;
}
.layout-preview.selected label{
  color: #fd397a;
}

.module-config-tab.nav-tabs.nav-tabs-line .nav-link{
  padding: 14px 10px;
}

.module-config-tab.nav-tabs.nav-tabs-line.nav-tabs-line-3x a.nav-link{
  border-bottom-width: 4px !important;
}

.select-box-is-invalid {
  border-color: #fd397a !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fd397a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fd397a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.325rem);
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.btn.btn-perfect-color{
  background-color: #fd397a;
  border-color: #fd397a;
  color: #ffffff;
  border-radius: 0;
}

.btn.btn-perfect-color.left{
  border-top-left-radius: 0.25rem;
}
.btn.btn-perfect-color.right{
  border-top-right-radius: 0.25rem;
}

.btn.btn-perfect-secondary{
  background-color: transparent;
  border-bottom: 1px solid #e2e5ec;
  color: #fd397a;
  border-radius: 0;
}

/* hide clear button in microsoft browsers: ie 10, ie 11 and edge */
input[type=text]::-ms-clear {
  display: none;
}
