.layout-main_logo {
    width: 40%;
    display: block;
    margin: auto;
    padding-bottom: 50px;
}

.signin-main_button-detail {
    margin: 0 5px;
}

.signup-main_logo {
    width: 40%;
    display: block;
    margin: auto;
    padding-bottom: 50px;
}

.signup__form-note {
    font-size: .9em;
    text-align: right;
    margin: 1em 0 .3em;
    color: #6d6d6d;
    font-style: italic;
}

.tooltip .tooltip-inner {
    padding: 5px 8px;
    background: #000000;
    color: #ffffff;
    opacity:0.8;
}