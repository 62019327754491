.checkbox-story {
  margin-bottom: 5px;
  text-align: left;
}

.checkbox-story-label {
  margin-left: 20px;
  white-space: nowrap;
}

.checkbox-story input[type='radio'],
.checkbox-story input[type='checkbox'] {
  display: none;
}

.checkbox-story label::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 22px;
  border: solid 1px #212529;
  background-color: #fff !important;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  overflow: auto;
  margin-top: 4px;
}

.checkbox-story input[type='checkbox']:checked + label::after,
.checkbox-story input[type='radio']:checked + label::after {
  content: '';
  background-image: url('../../../assets/images/selected.png');
  background-size: cover;
}

.checkbox-story label::after {
  position: absolute;
  width: 7px;
  height: 7px;
  left: 25px;
  margin-top: 7.5px;
  font-size: 11px;
}

.pf-layout-container-success {
  text-align: -webkit-center;
}

.pf-success-story__back {
  text-align: left;
}

.pf-success-story__back .guide-back {
  position: relative;
  top: 10px;
  background-color: #ea3a77;
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.pf-success-story-block {
  display: inline-flex;
  margin-bottom: 60px;
  margin-top: 60px;
  justify-content: space-between;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -webkit-justify-content: space-between;
  width: 100%;
}

.pf-success-story-title-block {
  width: 55%;
}

.pf-success-story-title {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.pf-success-story-feature {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #333333;
  margin-right: 20px;
  white-space: nowrap;
}

.pf-success-story-title-lg {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: #f23b77;
}

.pf-success-story-content {
  font-size: 18px;
  font-style: italic;
  line-height: 1.56;
  text-align: left;
  color: #333333;
  margin: 29px 0 26px;
}

.pf-success-story-brand {
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #333333;
}

.pf-success-story-title-cover {
  max-width: 411px;
  position: relative;
  width: 40%;
}

.pf-story-cover-button {
  width: 100%;
  position: absolute;
  display: flex;
  opacity: 0.9;
  line-height: 1.38;
  font-weight: 500;
  align-items: center;
  text-align: left;
  bottom: 0;
  padding: 9px 0 10px;
}

.pf-story-cover-button-padding {
  padding: 0 10px 0 37px;
}

.pf-story-cover-text-margin {
  margin-right: 38px;
  width: 75%;
  word-wrap: break-word;
}

.pf-success-story-situation-block {
  display: flex;
}

.pf-success-story-card {
  padding: 24px 22px 36px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  max-width: 241px;
  display: inline-table;
  position: relative;
}

.pf-success-story-card-fixed {
  padding: 24px 22px 36px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  max-width: 241px;
  position: fixed;
  top: 120px;
}

.pf-success-story-card-fixed2 {
  padding: 24px 22px 36px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  max-width: 241px;
  position: absolute;
}

.pf-success-story-card-image {
  padding-bottom: 15.5px;
  margin: 0 auto;
}

.pf-success-story-card-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  text-align: left;
  color: #212529;
  padding: 23.5px 0 16.5px;
  border-top: solid 0.5px #ccc;
}

.pf-success-story-list-block {
  margin-bottom: 16px;
  font-size: 14px;
  color: #212529;
  text-align: left;
}

.pf-success-story-card-list {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  text-align: left;
  color: #212529;
  margin-bottom: 14px;
  word-wrap: break-word;
}

.pf-success-stroy-contact-button {
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-radius: 6px;
  background-color: #f23b77;
  font-size: 16px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  margin-top: 22px;
}

.pf-success-stroy-contact-button:hover {
  background-color: #ff72a0;
}

.pf-success-story-situation-subtitle {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.04;
  text-align: left;
  color: #f23b77;
  margin: 0 0 12px;
}

.pf-success-story-image-content {
  font-size: 23px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.3;
  text-align: left;
  color: #000000;
}

.pf-success-story-image-content-title {
  font-size: 25px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.3;
  text-align: left;
  color: #000000;
  padding-bottom: 15px;
}

.pf-success-story-image-block {
  display: flex;
  align-items: center;
}

.pf-success-story-cover-des {
  margin-left: 37px;
}

.pf-success-story-title-image img {
  height: 100%;
  max-height: 45px;
  overflow: hidden;
}

.pf-success-story-situation {
  width: 77%;
  padding-left: 90px;
}

.pf-success-story-situation body {
  overflow: hidden;
  background-color: #fff;
}

.pf-success-story-situation-ja body {
  font-family: 'Roboto', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    メイリオ, Meiryo, 'ＭＳ Ｐゴシック', sans-serif, Segoe UI, Helvetica,
    Microsoft JhengHei, Arial, 'sans-serif' !important;
}

.ul-custom {
  padding-left: 20px;
}

.pf-success-story-ja-small-word {
  text-align: right;
  font-size: 14px;
}

.pf-success-story-smb-contact-btn {
  padding-top: 24px;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  color: #007bff;
  text-align: center;
}

.pf-success-story-content-image {
  margin: 0 auto;
  text-align: center;
}

.pf-success-story-image-block2 {
  width: 60%;
}

.pf-success-story-image-block2-content {
  width: 40%;
}

.pf-story-explantion-sm {
  text-align: right;
  font-size: 14px;
}

@media screen and (min-width: 1800px) {
  .pf-success-story-title-block {
    width: 60%;
  }
}

@media screen and (max-width: 1280px) {
  .pf-success-story-image-block {
    display: block;
    text-align: center;
  }

  .pf-success-story-content-image {
    padding-bottom: 15px;
  }

  .pf-success-story-cover-des {
    margin-left: 0;
  }

  .pf-success-story-feature {
    font-size: 14px;
  }

  .pf-story-cover-button-padding {
    padding: 0 10px 0 15px;
  }

  .pf-story-cover-text-margin {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .pf-success-story-situation {
    width: 55%;
  }

  .pf-success-story-title-cover {
    width: 46%;
  }

  .pf-success-story-title-block {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .pf-layout-container-success {
    width: 85%;
  }

  .pf-success-story-title {
    margin-top: 30px;
    margin-bottom: 27px;
    display: block;
    align-items: center;
  }

  .pf-success-story-feature {
    margin-right: 12px;
  }

  .pf-success-story-block-mobile {
    display: block;
  }

  .pf-success-story-situation-block {
    display: block;
  }

  .pf-success-story-title-lg {
    font-size: 30px;
    line-height: 1.2;
  }

  .pf-success-story-content {
    margin: 22px 0 21px;
  }

  .pf-success-story-brand {
    padding-bottom: 30px;
  }

  .pf-success-story-card {
    padding: 24px 0 15px;
    max-width: 352px;
    margin: 0 auto 50px;
  }

  .pf-success-story-card-image {
    padding: 0 22px 22.5px;
  }

  .pf-success-stroy-contact-button {
    display: none;
  }

  .pf-success-story-image-content-title {
    font-size: 18px;
    line-height: 1.56;
    padding-bottom: 8px;
  }

  .pf-success-story-image-content {
    font-size: 16px;
    line-height: 1.56;
  }

  .pf-success-story-situation-subtitle {
    line-height: 1.14;
  }

  .pf-success-story-situation {
    width: 100%;
  }

  .pf-success-story-title-cover {
    max-width: 352px;
    width: 100%;
    margin: 0 auto 30px;
  }

  .pf-success-story-card-title {
    padding: 23.5px 22px 16.5px;
    border: none;
  }

  .pf-success-story-list-block {
    margin: 0 22px 16px;
  }

  .pf-success-story-card-list {
    padding: 0 22px 0 0;
  }

  .pf-success-story-content-image {
    max-width: 600px;
  }

  .pf-success-story__back .guide-back {
    left: -20px;
  }

  .pf-success-story-ja-small-word {
    text-align: left;
  }

  .pf-success-story-smb-contact-btn {
    padding: 10px 0 32px;
  }

  .pf-story-cover-button-padding {
    padding: 0 10px 0 20px;
  }

  .pf-story-cover-text-margin {
    margin-right: 3px;
  }

  .pf-success-story-title-block {
    width: 100%;
  }

  .pf-success-story-image-block2 {
    width: 100%;
  }

  .pf-success-story-image-block2-content {
    width: 100%;
  }

  .pf-story-explantion-sm {
    text-align: left;
  }
  .mobile-line {
    border-top: 1px solid #ccc;
    width: 90%;
  }
}
