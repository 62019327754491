.pf-news__preview {
  background-color: #fff;
  position: absolute;
  top: 0;
  padding-top: 30px;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', '',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.pf-news__preview-pair {
  display: flex;
  width: 100%;
}

.pf-news__preview-key {
  font-size: 15px;
  font-weight: 500;
  width: 10%;
}

.pf-news__preview-val {
  font-size: 15px;
  font-weight: 400;
  width: 90%;
}

.pf-news__preview-card {
  text-align: left;
  margin: 0 auto;
}

.pf-news__preview-card-item {
  display: inline-block;
  background-color: #fff;
  border-bottom: none;
  box-shadow: 0 0 1.3rem rgba(0, 0, 0, 0.2);
  width: 285px;
}

.pf-news__preview-card-item img {
  max-width: 100%;
  padding-bottom: 25px;
}

.pf-news__preview-card-date {
  box-shadow: none;
  font-size: 15px;
  color: #7f7f7f;
  text-align: left;
  padding: 0 16px 20px;
}

.pf-news__preview-card-txt {
  box-shadow: none;
  color: #212529;
  text-align: left;
  padding: 0 16px;
  line-height: 1.59;
  overflow: hidden;
  font-size: 17px;
  font-weight: 500;
  height: 160px;
}

.pf-news__preview-card-read {
  display: block;
  padding: 16px 16px 23px;
  font-weight: 700;
  text-align: right;
  color: #ed3f78;
  font-size: 15px;
}

.pf-news__preview-card-read img {
  width: 25px;
  height: 14px;
  margin-left: 15px;
  padding-bottom: 0;
}

.pf-news__preview-container-top {
  display: flex;
  width: 80%;
  background-color: #fff;
  margin: 0 auto;
  text-align: left;
  padding: 15px 20px;
}

.pf-news__preview-container {
  width: 85%;
  background-color: #fff;
  margin: 20px auto;
  word-break: break-word;
}

.pf-news-detail-page {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 20px auto 60px;
}

.pf-news-detail-page .news-article-info {
  height: 100%;
  margin: 0 auto;
}

.pf-news-detail-page .news-date {
  float: left;
  width: 20%;
  padding-bottom: 15px;
  border-right: 2px solid #000000;
}

.pf-news-detail-page .news-title {
  font-size: 21px;
  line-height: 40px;
  width: 73%;
  margin-left: 30px;
  font-weight: 300;
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pf-news-detail-page .news-article-main {
  margin: 20px 1% 1%;
  padding: 2%;
  float: left;
  line-height: 22px !important;
}

.pf-news-detail-page .news-date h1 {
  text-align: center;
}

.pf-news-detail-page img {
  max-width: 100% !important;
  height: auto !important;
}

.pf-news-detail-page .guide-back {
  position: relative;
  top: -10px;
  background-color: #ea3a77;
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.news-date-top {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 10px;
}

.news-date-bottom {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.news-top-block {
  display: flex;
}

.news-more-btn {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border: 1px solid #ef3f78;
  background-color: #ef3f78;
  padding: 0 40px;
  border-radius: 30px;
  font-weight: 600;
}

.news-more-btn:hover {
  color: #fff;
  text-decoration: none;
  background-color: #ff72a0;
  border: 1px solid #ff72a0;
}

.pf-content-text2 {
  background-color: #fff;
}

@media screen and (max-width: 1400px) {
  .pf-news__preview-key {
    width: 15%;
  }

  .pf-news__preview-val {
    width: 85%;
  }
}

@media screen and (max-width: 1000px) {
  .pf-news__preview-key {
    width: 20%;
  }

  .pf-news__preview-val {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .pf-news-detail-page .pf-content-text2__title {
    font-size: 24px;
  }

  .pf-news-detail-page .pf-content-text2 {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5% 5% 5%;
  }

  .pf-news-detail-page .news-date {
    width: 100%;
    border-right: none;
  }

  .pf-news-detail-page .news-date h1 {
    font-size: 37px;
    line-height: 10px;
  }

  .pf-news-detail-page .news-title {
    width: 100%;
    font-size: 19px;
    line-height: 28px;
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0;
    border-top: 1px solid #000000;
    border-left: 0 solid #000000;
    text-align: center;
  }

  .pf-news-detail-page .guide-back {
    left: 2.5%;
  }

  .news-top-block {
    display: block;
  }

  .pf-news-detail-page .news-article-info {
    width: 95%;
  }

  .pf-news-detail-page .news-article-main {
    margin: 0;
    padding: 0;
    line-height: 28px !important;
  }

  .pf-news__preview-key {
    width: 30%;
  }

  .pf-news__preview-val {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .news-more-btn {
    display: inline-block;
    height: 35px;
    line-height: 35px;
    color: #fff;
    border: 1px solid #ef3f78;
    background-color: #ef3f78;
    padding: 0 20px;
    border-radius: 30px;
    font-weight: 600;
    margin-top: 20px;
  }

  .pf-cover-image {
    width: 100%;
  }

  .pf-news__preview-pair {
    display: block;
  }

  .pf-news__preview-key {
    width: 100%;
  }

  .pf-news__preview-val {
    width: 100%;
    padding: 10px 0;
  }
}

.news-article-main body {
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  line-height: 28px !important;
}

.news-cover-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 10px;
}

.pf-cover-image {
  width: 24%;
  margin: auto;
  padding-bottom: 24px;
}

.news-content {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 30px;
}

.pf-preview-news {
  position: absolute;
  background-color: rgb(255, 255, 255);
  top: 0;
  width: 100vw;
  height: 100vh;
}

.pf-news-content {
  display: inline-block;
  height: fit-content;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
