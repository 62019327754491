.upload {
    padding: 1rem 1rem !important;
    background-color: #ffb822;
    cursor: pointer !important; ;
}

.filePicker:disabled {
    background-color: white ;
}

.filePicker{
    cursor: pointer;
}