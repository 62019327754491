.scroll-to-top {
    position: fixed;
    right: 30px;
    bottom: 40px;
    cursor: pointer;
    color: #687991;
    font-size: 30px;
    opacity: 0.6;
    padding: 10px;
    border: none;
    background-color: transparent;
}

.scroll-to-top i {
    opacity: 0.6;
}

.scroll-top-top:hover i {
    opacity: 1;
    color: #5d78ff;
}
