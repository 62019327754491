.image-editor-block {
    display: inline-block;
    text-align: center;
}

.image-editor-dropzone-block {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
}

.image-editor-close {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: rgba(225, 225, 239, 0.5);
    display: none !important;
}

.image-editor-dropzone-block:hover .image-editor-close {
    display: initial !important;
}

.image-editor-hint {
    width: 100%;
    height: 100%;
    border: 3px dashed #666;
}

.image-editor-image-scale-block {
    margin: 28px 0 0 0;
}

.image-editor-image-scale {
    margin: 0 10px;
    cursor: pointer;
}
