.modify_____modal {
  position: relative;
  transform: translateX(-21%);
  width: 200%;
}
.displayorder-viewform___modal {
  width: 90vw;
}

.displayorder-viewform___modal-body {
  padding: 20px;
  overflow: auto;
  margin-right: 1px;
}

.displayorder-viewform___table-label {
  margin: 0;
  flex-basis: 210px;
  flex-shrink: 0;
}
.display-btn {
  display: flex;
  margin: 0 auto;
}

.flex__justify-space-between {
  justify-content: space-between;
}
.flex__item-align-center {
  align-items: center;
}
.flex__block {
  display: flex;
}
